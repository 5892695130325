import { AddressDTO, WhitelabelUserAddressDto } from 'libs/common/src/models/GeneralModels';
import { DeliveryOrderDetailedReadDTO, DeliveryOrderMinimalReadDTO } from 'libs/common/src/models/order/OrderModels';
import * as yup from 'yup';
import { EMAIL_PATTERN } from './GlobalConstant';

export function getAddressToDeliveryOrder(data?: DeliveryOrderDetailedReadDTO | DeliveryOrderMinimalReadDTO, hideCountry = true) {
	return getAddress(data?.deliveryAddress, hideCountry);
}

export function getAddress(data?: AddressDTO, hideCountry = true, ) {
	let address: string[] = [];
	if (data) {
		if (data?.zip) {
			address.push(data.zip);
		}

		if (data?.country && !hideCountry) {
			address.push(`${data.country},`);
		}

		if (data?.city) {
			address.push(`${data.city},`);
		}

		if (data?.address) {
      address.push((data.address ?? '').replace(/sgt/g, 'sugárút'));
		}

		if (data?.number) {
			address.push(data.number);
		}

		if (data?.building) {
			address.push(data.building);
		}

		if (data?.floor) {
			address.push(data.floor);
		}

		if (data?.apartment) {
			address.push(data.apartment);
		}

		if (data?.intercom) {
			address.push(data.intercom);
		}
	}
	return address.join(' ');
}

export const addressValidationSchema = yup.object().shape({
  // Egyéb általános mezők validációja
  email: yup.string().matches(EMAIL_PATTERN, 'Required'),
  country: yup.string().required('Required'),
  state: yup.string().nullable(),
  city: yup.string().required('Required'),
  zip: yup.string().required('Required'),
  address: yup.string().required('Required'),
  number: yup.string().nullable(),
  building: yup.string().nullable(),
  staircase: yup.string().nullable(),
  floor: yup.string().nullable(),
  apartment: yup.string().nullable(),
  intercom: yup.string().nullable(),
  additional: yup.string().nullable(),
  // position: yup.object({
  //   lat: yup.number().required('Latitude is required'),
  //   lon: yup.number().required('Longitude is required')
  // }).nullable(),
  vatNumber: yup.string().nullable(),
  firstName: yup.string().nullable(),
  lastName: yup.string().nullable(),
  companyName: yup.string().nullable(),
  companyRegistrationNumber: yup.string(),
  tag: yup.string().nullable(),
  type: yup.string().oneOf(['DELIVERY', 'BILLING', 'LOCATION']).required('Required')
}).test('billing-fields', 'Billing fields are required when type is BILLING', function(value) {
  const { email, companyName, companyRegistrationNumber, vatNumber, type } = value;
  if (type === 'BILLING') {
    if ((`${email ?? ''}`).length === 0 || (`${companyName ?? ''}`).length === 0 || (`${companyRegistrationNumber ?? ''}`).length === 0 || (`${vatNumber ?? ''}`).length === 0) {
      return this.createError({
        message: 'Required fields are missing for billing type'
      });
    }
  }
  return true;
}).test('position-fields', 'Billing fields are required when type is BILLING', function(value) {
  const { position, type }: any = value;
  if (type === 'DELIVERY') {
    if (!position?.lat || !position?.lon) {
      return this.createError({
        message: 'Required position fields are missing for delivery type'
      });
    }
  }
  return true;
});

export const getAddressDisplay = (address?: WhitelabelUserAddressDto | AddressDTO) => {
  return `${address?.zip ?? ''} ${address?.city ?? ''}, ${address?.address ?? ''} ${address?.number ?? ''}`;
};
